import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './BookDemo.css'
import iconocerrar from './images/icono-cerrar.svg'

import { Demo } from "./Api"

const demoCtrl = new Demo() ;


function BookDemo()
{

    function handleSubmit(event) {
        event.preventDefault();

        var theForm = document.getElementById('myForm') ;
    
        const data = new FormData(theForm);
    
        const value = Object.fromEntries(data.entries());

        //JSON.stringify(Object.fromEntries(data))
    
        console.log(JSON.stringify(value) );


        console.log( document.getElementById('name4').value) ;

            const mydata = {
                name : document.getElementById( "name4").value ,
                lastname : document.getElementById("lastname4").value ,
                email : document.getElementById( "inputEmail4").value , 
                phone : document.getElementById( "phone4").value , 
                company : document.getElementById( "company4").value , 
                position : document.getElementById( "position4").value , 

            }

        console.log( mydata ) ;

        (async() => {
            await demoCtrl.setDemo( mydata , callBack)

            console.log( "Ya termino el setDemo")

            //console.log(  response  ) ;
        })()
        

    }

    function callBack( response )
    {
        console.log( "response en callBack = " + JSON.stringify( response ) ) ;
    }

    return (



        <div className='book-in  d-flex align-items-center'>   
            <div className='container-fluid p-5 fixed-top'>
                <div className='pos-1 d-flex justify-content-end'>
                            
                    
                    <div className='d-flex'>

                        <div className='px-3 mb-0 align-self-center'>
                            <p className='m-0 body-3 text-white'>En | Sp</p>
                        </div>

                        <div className=''>
                            <a  href="#"><img src={iconocerrar}  alt="menu"/></a>              
                        </div>

                    </div>
                </div>
            </div>    

            <div className='container'>             
                
                <div className='row'>               
                    <div className='col-6'>
                    
                        <h2 className='h1 font-bold text-white'>Book a Demo</h2>
                        
                        <p className='body-1'>Thank you for your interest in out online platform. To provide you with personalized experience, we need some additional details. Please fill out the following form to request a demo to our platform. </p>

                    </div>


                    <div className='col-6 vl'>

                        <form id="myForm" onSubmit={handleSubmit}>
                            <div className="form-row d-flex flex-column gap-3">
                                <div className="form-group">
                                    <label htmlFor="name4">Name</label>
                                    <input type="text" className="form-control" id="name4" placeholder="Name"/>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="lastname4">Last Name</label>
                                    <input type="text" className="form-control" id="lastname4" placeholder="Last Name"/>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="inputEmail4">Email</label>
                                    <input type="email" className="form-control" id="inputEmail4" placeholder="Email"/>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="phone4">Phone</label>
                                    <input type="tel" className="form-control" id="phone4" placeholder="Phone"/>
                                </div>
                                <div>
                                    <label htmlFor="company4">Company</label>
                                    <input type="text" className="form-control" id="company4" placeholder="Company"/>
                                </div>

                                <div className="form-group">    
                                    <label htmlFor="position4">Position</label>
                                    <input type="text" className="form-control" id="position4" placeholder="Position"/>
                                </div>
                            </div>

        
                            <button type="submit"  className="button-1 mt-5 text-white">Request a Demo</button>

                        </form>

                    </div>

                </div> 

            </div>

            {/* <div style={{height:400}}></div> */}
        </div>  

    )

}

export default BookDemo ;